import React, { useCallback, useEffect, useRef, useState } from "react";
import Title from "../../Components/Common/Title/Title";
import userPhoto from "../../assets/images/profile-picture.png";
import totalWon from "../../assets/images/myscrapshop/win2.png";
import gamesWon from "../../assets/images/myscrapshop/wins.png";
import creditBalanceBg from "../../assets/images/myscrapshop/priceBg.png";
import popUp from "../../assets/images/myscrapshop/popUp.png";
import Minipack1 from "../../assets/images/myscrapshop/Minipack1.png";
import Minipack3 from "../../assets/images/myscrapshop/Minipack3.png";
import Minipack5 from "../../assets/images/myscrapshop/Minipack5.png";
import Card1 from "../../assets/images/myscrapshop/1.png";
import Card2 from "../../assets/images/myscrapshop/2.png";
import Card3 from "../../assets/images/myscrapshop/3.png";
import Fruitmachine from "../../assets/images/myscrapshop/Fruitmachine.png";
import svgCard1 from "../../../public/cardsvgs/1.svg";
import btnBg from "../../assets/images/deckBuilder/btnBackground.png";
import "./MyScrapShop.scss";
import {
  pico1v1Contract,
  storageContract,
  scrapshopContract,
  cardContract,
} from "../../web3/contracts";
import { ethers } from "ethers";
import { useWeb3 } from "../../web3/Web3Context";
import { notify } from "../../utils/customToast";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import InfoModal from "../../Components/Common/Modals/InfoModal/InfoModal";
import Loader from "../../Components/Common/Loader/Loader";
import cardMetadata from "../../web3/cardMetadata.json";
import ToggleSwitch from "../../Components/Common/UI/ToggleSwitch/ToggleSwitch";
import CardsMinted from "../../Components/Common/Modals/CardsMinted/CardsMinted";
import { useNavigate } from "react-router-dom";
import Modal from "../../Components/Common/Modal/Modal";
import scrapShop10 from "../../assets/images/myscrapshop/10.png";
import scrapShop11 from "../../assets/images/myscrapshop/11.png";
import scrapShop12 from "../../assets/images/myscrapshop/12.png";

import scrapShop20 from "../../assets/images/myscrapshop/20.png";
import scrapShop21 from "../../assets/images/myscrapshop/21.png";
import scrapShop22 from "../../assets/images/myscrapshop/22.png";

import scrapShop30 from "../../assets/images/myscrapshop/30.png";
import scrapShop31 from "../../assets/images/myscrapshop/31.png";
import scrapShop32 from "../../assets/images/myscrapshop/32.png";
type Props = {};
const cardsList = [
  {
    src: "cardsvgs/55.svg",
    value: 150,
  },
  {
    src: "cardsvgs/55.svg",
    value: 100,
  },
  {
    src: "cardsvgs/55.svg",
    value: 250,
  },
  {
    src: "cardsvgs/55.svg",
    value: 200,
  },
  {
    src: "cardsvgs/55.svg",
    value: 200,
  },
  {
    src: "cardsvgs/55.svg",
    value: 200,
  },
];

type Card = {
  id: number;
  cardType: number;
  image: string;
  name: string;
  rarity: number;
  cattype: number;
  strength: number;
};

const MyScrapShop: React.FC<Props> = () => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState<Card[]>([]);
  const { myAddress, isConnected } = useWeb3();
  const [yourTrophiesData, setYourTrophiesData] = useState<any>(null);
  const [tournamentPlacements, setTournamentPlacements] = useState<any[]>([]);
  const [creditsBalance, setCreditBalance] = useState<any>(undefined);
  const [notAvailableToBuy, setNotAvailableToBuy] = useState<boolean>(false);
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [storageallData, setstorageAllData] = useState<Card[]>([]);
  const [costBulk, setcostBulk] = useState<number>(0);
  const [storagetotal, setstorageTotal] = useState<number>(0);
  const [storagefilteredData, setstorageFilteredData] = useState<Card[]>([]);
  const [cardAccess, setCardAccess] = useState<boolean>(false);
  const [newCardsPurchased, setNewCardsPurchased] = useState<any>(undefined);
  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false);
  const [scrapModalOpen, setScrapModalOpen] = useState<boolean>(false);
  console.log("yourTrophiesData", yourTrophiesData);
  console.log("tournamentPlacements", tournamentPlacements);
  const getCardsBatch = async (
    tokenIds: Card[],
    from: number,
    maxLength: number
  ) => {
    try {
      let cardBatch;

      cardBatch = await storageContract?.getStoredCardsBatch(myAddress, from);

      for (let i = 0; i < cardBatch[0].length; i++) {
        const cardType = Number(cardBatch[1][i]);
        const metadata = cardMetadata.find((meta) => meta.id === cardType);
        if (metadata) {
          const isMutant = cardBatch[2][i];
          tokenIds.push({
            id: Number(cardBatch[0][i]),
            cardType: cardType,
            image: isMutant
              ? `mutantsvgs/${cardType}.svg`
              : `cardsvgs/${cardType}.svg`,
            name: metadata.name,
            rarity: metadata.rarity,
            cattype: metadata.type,
            strength: metadata.strength,
          });
        }
      }

      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    } catch (e) {
      console.error(e);
      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    }
  };
  const storageGetAllCards = useCallback(async () => {
    try {
      const creditBalance = await storageContract?.creditBalances(myAddress);
      setCreditBalance(Number(creditBalance));
      let tokenIds: Card[] = [];
      let balance = await storageContract?.getStoredLength(myAddress);
      if (balance && Number(balance) > 0) {
        setstorageTotal(Number(balance));
        await getCardsBatch(tokenIds, 0, Number(balance));
        setstorageAllData(tokenIds);
        setstorageFilteredData(tokenIds);
      }
    } catch (e) {
      //   notify("Failed to fetch balance", "error");
      console.error(e);
    }
  }, [myAddress]);
  useEffect(() => {
    storageGetAllCards();
  }, [myAddress]);

  console.log("creditsBalance", creditsBalance);
  useEffect(() => {
    const fetchTrophiesData = async () => {
      if (!pico1v1Contract || !myAddress) {
        console.error("Contract or address is undefined");
        return;
      }

      try {
        console.log("Fetching data for address:", myAddress);
        const userData = await pico1v1Contract.users(myAddress);
        console.log("Data fetched from contract:", userData);

        const sfortWonParsed = Math.floor(
          Number(ethers.utils.formatEther(userData.sfortwon))
        );

        setYourTrophiesData({
          currentRoom: userData.currentroom.toString(),
          gamesWon: userData.gameswon.toString(),
          gamesPlayed: userData.gamesplayed.toString(),
          sfortWon: sfortWonParsed.toString(),
          cardsBurnt: userData.cardsburnt.toString(),
          cardsLost: userData.cardslost.toString(),
        });
      } catch (error) {
        console.error("Error fetching trophies data:", error);
      }
    };

    const fetchTournamentData = async () => {
      if (!pico1v1Contract || !myAddress) return;

      try {
        // Get the current tournament number
        const currentTournament = await pico1v1Contract.tournamentNumber();
        const currentTournamentNumber = currentTournament.toNumber();
        const tournamentsToFetch = Math.min(currentTournamentNumber, 5); // Fetch up to 5 previous tournaments

        const placementPromises = [];
        for (let i = 0; i < tournamentsToFetch; i++) {
          const tournamentNumber = currentTournamentNumber - 1 - i;
          placementPromises.push(fetchTournamentPlacement(tournamentNumber));
        }

        const placements = await Promise.all(placementPromises);
        setTournamentPlacements(placements.filter(Boolean)); // Filter out null responses
      } catch (error) {
        console.error("Error fetching tournament data:", error);
      }
    };

    const fetchTournamentPlacement = async (tournamentNumber: number) => {
      try {
        const { winners, prizes } = await pico1v1Contract?.getTournamentWinners(
          tournamentNumber
        );
        const winnerIndex = winners.findIndex(
          (winner: string) => winner.toLowerCase() === myAddress?.toLowerCase()
        );

        if (winnerIndex !== -1) {
          // User placed in the tournament
          const prize = ethers.utils.formatEther(prizes[winnerIndex]);
          return `Tournament #${tournamentNumber} - Placed ${
            winnerIndex + 1
          } - won ${prize} $FORT`;
        } else {
          // User didn't place in the top 10
          return `Tournament #${tournamentNumber} - Not Top 10`;
        }
      } catch (error) {
        console.error(
          `Error fetching tournament #${tournamentNumber} data:`,
          error
        );
        return null;
      }
    };

    if (isConnected && myAddress) {
      fetchTrophiesData();
      fetchTournamentData();
    }
  }, [isConnected, myAddress]);

  useEffect(() => {
    storageGetAllCards();

    checkApprovalForAll();
  }, [myAddress]);

  const handleScrollLeft = () => {
    if (containerRef.current && currentIndex > 0) {
      const container = containerRef.current;
      const itemWidth = container.children[0].clientWidth; // Width of a single item
      container.scrollBy({ left: -itemWidth, behavior: "smooth" });
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleScrollRight = () => {
    if (
      containerRef.current &&
      currentIndex < containerRef.current.children.length - 1
    ) {
      const container = containerRef.current;
      const itemWidth = container.children[0].clientWidth; // Width of a single item
      container.scrollBy({ left: itemWidth, behavior: "smooth" });
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const updateCurrentIndexOnScroll = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const itemWidth = container.children[0].clientWidth;
      const scrollLeft = container.scrollLeft;

      // Calculate the new index
      const newIndex = Math.round(scrollLeft / itemWidth);
      setCurrentIndex(newIndex);
    }
  };

  const isAtEnd = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      return Math.abs(container.scrollLeft - maxScrollLeft) < 1; // Tolerance for floating-point precision
    }
    return false;
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", updateCurrentIndexOnScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateCurrentIndexOnScroll);
      }
    };
  }, []);

  const toggleSelection = (item: any) => {
    const isSelected = selectedItems.some(
      (selected) => selected.id === item.id
    );
    if (isSelected) {
      setSelectedItems(
        selectedItems.filter((selected) => selected.id !== item.id)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const checkApprovalForAll = useCallback(async () => {
    if (!cardContract || !storageContract || !myAddress) return;
    try {
      const isApproved = await cardContract.isApprovedForAll(
        myAddress,
        storageContract.address
      );
      setCardAccess(isApproved);
      const cost = await storageContract?.transferFee();
      setcostBulk(Number(cost));
    } catch (error) {
      console.error("Error checking approval status:", error);
    }
  }, [cardContract, storageContract, myAddress]);

  const handleBuy = async (data: any, value: number) => {
    const buyitem = Number(data);
    if (creditsBalance < value) {
      setNotAvailableToBuy(true);
    } else {
      try {
        setMainLoader(true);
        const overrides = { gasLimit: ethers.utils.hexlify(8000000) };
        const tx = await scrapshopContract?.buyCreditItem(buyitem, overrides);
        await tx.wait();
        notify("Bought Scrap Item!", "success");
      } catch (error) {
        console.error("Transaction failed:", error);
        notify("Failed to buy scrap Item", "error");
      } finally {
        setMainLoader(false);
      }
    }
  };

  const handleCrap = () => {
    console.log("selectedItems", selectedItems);
  };

  function calculateTotalCreditPoints(cards: any) {
    // Map through the cards array to calculate credit points for each card
    const results = cards.map((card: any) => {
      const creditPoints = (card.rarity + 1) * 100 + (card.strength + 1) * 50;
      return {
        id: card.id,
        name: card.name,
        creditPoints,
      };
    });

    // Calculate the total credit points
    const totalCreditPoints = results.reduce(
      (sum: any, card: any) => sum + card.creditPoints,
      0
    );

    // Return both the individual results and the total
    return {
      cards: results,
      totalCreditPoints,
    };
  }

  const calculateCreditPoints = (card: any) => {
    return (card.rarity + 1) * 100 + (card.strength + 1) * 50;
  };

  const toggleCardAccess = async (checked: boolean) => {
    setCardAccess(checked);
    if (!cardContract || !storageContract || !myAddress) return;
    try {
      const tx = await cardContract.setApprovalForAll(
        storageContract.address,
        checked
      );
      await tx.wait();
      setIsApprovedForAll(checked);
      console.log(`Card access ${checked ? "approved" : "revoked"}`);
    } catch (error) {
      console.error("Error setting card access approval:", error);
    }
  };

  console.log("cardAccess", cardAccess);

  const bulkScrapSelected = async () => {
    setMainLoader(true);
    try {
      const cardIds = selectedItems.map((card) => Number(card.id));
      const overrides = {
        gasLimit: ethers.utils.hexlify(8000000),
        value: ethers.utils.parseUnits(costBulk.toString(), 18),
      };
      const tx = await storageContract?.recycleCards(cardIds, overrides);
      // TODO: Send array with all new cards (string array)
      //   setNewCardsPurchased([])
      await tx.wait();

      notify("Sent to Address!", "success");
    } catch (error) {
      console.error("Transaction failed:", error);
      notify("Failed to send to storage", "error");
    } finally {
      setMainLoader(false);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="myScrapShop">
        <Title title="SCRAP SHOP" />
        <p className="description">
          Welcome to the scrap shop, here you can spend credits for special
          items. You can earn credits by playing PiCO or you can scrap old
          unwanted cards.
        </p>
        <div className="profile-info">
          <div className="user">
            <img src={userPhoto} alt="User photo" />
          </div>
          <div className="user-info-box">
            <div className="box">
              <label>$FORT WON</label>
              <img src={totalWon} alt="Total won" />
              <span>{yourTrophiesData?.sfortWon}</span>
            </div>
            <div className="box">
              <label>GAMES WON</label>
              <img src={gamesWon} alt="games Won" />
              <span>{yourTrophiesData?.gamesWon}</span>
            </div>
          </div>
        </div>
        <div className="credit-balance">
          <label>CREDITS BALANCE</label>
          <span>{creditsBalance}</span>
        </div>
        <div className="scrapShopItems">
          <h3>SCRAP SHOP ITEMS</h3>
          <p>Here you can purchase special items using your credits.</p>
          <div className="container">
            <div className="item">
              <div className="top miniPack">
                <img src={Minipack1} alt="Mini pack 1" />
              </div>
              <div className="middle">
                <span>2,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(0, 2000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top miniPack">
                <img src={Minipack3} alt="Mini pack 3" />
              </div>
              <div className="middle">
                <span>5,500</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(1, 5500)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top miniPack">
                <img src={Minipack5} alt="Mini pack 5" />
              </div>
              <div className="middle">
                <span>10,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(2, 10000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop10} alt="Photo" />
              </div>
              <div className="middle">
                <span>3,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(3, 3000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop11} alt="Photo" />
              </div>
              <div className="middle">
                <span>3,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(4, 3000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop12} alt="Photo" />
              </div>
              <div className="middle">
                <span>3,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(5, 3000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop20} alt="Photo" />
              </div>
              <div className="middle">
                <span>7,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(6, 7000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop21} alt="Photo" />
              </div>
              <div className="middle">
                <span>7,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(7, 7000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop22} alt="Photo" />
              </div>
              <div className="middle">
                <span>7,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(8, 7000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop30} alt="Photo" />
              </div>
              <div className="middle">
                <span>10,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(9, 10000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop31} alt="Photo" />
              </div>
              <div className="middle">
                <span>10,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(10, 10000)}>BUY</span>
              </div>
            </div>
            <div className="item">
              <div className="top">
                <img src={scrapShop32} alt="Photo" />
              </div>
              <div className="middle">
                <span>10,000</span>
                <span>CREDIT</span>
              </div>
              <div className="bottom">
                <span onClick={() => handleBuy(11, 10000)}>BUY</span>
              </div>
            </div>
          </div>
        </div>
        <div className="luckySpin">
          <div className="content">
            <h3>LUCKY SPIN</h3>
            <p>
              Mutagen, $FORT, card packs, or simply crap. Take a spin and try
              your luch
            </p>
            <div className="creditBalance">
              <span>20,000</span>
              <span>CREDIT</span>
            </div>
            <button className="spinButton">SPIN</button>
          </div>
          <div className="image">
            <img src={Fruitmachine} alt="Fruitmachine" />
          </div>
        </div>
        <ToggleSwitch
          checked={cardAccess}
          onChange={toggleCardAccess}
          title="CARD ACCESS"
        />
        <div className="storage">
          <h3>STORAGE</h3>
          <p>
            Here you can see the cards you have in storage. You can scrap
            storage cards for credits. Go to the my cards page if you want to
            add cards to your storage.
          </p>
          <div className="listContainer">
            {/* Left Arrow */}
            <button
              onClick={handleScrollLeft}
              disabled={currentIndex === 0}
              style={{ marginRight: "10px" }}
              className="leftArrow"
            >
              &larr;
            </button>
            <div
              ref={containerRef}
              className="storageList"
              style={{
                display: "flex",
                overflowX: "auto", // Allows touchpad scrolling
                scrollBehavior: "smooth",
                //   width: "300px", // Adjust as needed
              }}
            >
              {storageallData?.map((item: any, index: number) => (
                <div
                  className={`storage-item ${
                    selectedItems.some((selected) => selected.id === item.id)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => toggleSelection(item)}
                  style={{
                    border: selectedItems.some(
                      (selected) => selected.id === item.id
                    )
                      ? "2px solid #F0EBC0"
                      : "1px solid gray",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <img src={item.image} alt="Card" />
                  <div>{calculateCreditPoints(item)}</div>
                </div>
              ))}
            </div>
            {/* Right Arrow */}
            <button
              onClick={handleScrollRight}
              disabled={isAtEnd()}
              style={{ marginLeft: "10px" }}
              className="rightArrow"
            >
              &rarr;
            </button>
          </div>
          <div>
            <h4>
              Total Selected Value:{" "}
              {calculateTotalCreditPoints(selectedItems)?.totalCreditPoints}
            </h4>
            <PrimaryButton
              text="SCRAP"
              disabled={selectedItems?.length === 0}
              onClick={() => setScrapModalOpen(true)}
            />
          </div>
        </div>
      </div>
      {notAvailableToBuy && (
        <InfoModal
          openModal={notAvailableToBuy}
          closeModal={() => setNotAvailableToBuy(false)}
          handleBack={() => setNotAvailableToBuy(false)}
          title="You don't have anought credits."
          description={""}
        />
      )}
      <CardsMinted
        openModal={newCardsPurchased}
        type={"MINTED"}
        closeModal={() => setNewCardsPurchased(undefined)}
        cardImages={newCardsPurchased}
        handleYourCard={() => navigate("/my-cards")}
        primaryButtonText={"MY CARDS"}
      />
      {scrapModalOpen && (
        <Modal
          open={scrapModalOpen}
          onClose={() => {
            setScrapModalOpen(false);
          }}
        >
          <div className="relative modal-body scrapCards">
            <Title title="Watch out burning cards" />
            <p>
              Scrapping means you will burn all selected cards, you will receive
            </p>
            <span
              className="scrapTotalBtn"
              style={{ backgroundImage: `url(${btnBg})` }}
            >
              {calculateTotalCreditPoints(selectedItems)?.totalCreditPoints}
            </span>
            <p>This action can not be reverted</p>
          </div>
          <div
            className="closeModal"
            onClick={() => {
              setScrapModalOpen(false);
            }}
          >
            x
          </div>
          <div className="actionButtons">
            <PrimaryButton
              text={`SCRAP IT`}
              width={`244px`}
              onClick={() => bulkScrapSelected()}
            />
            <PrimaryButton
              text={`Cancel`}
              width={`244px`}
              onClick={() => {
                setScrapModalOpen(false);
              }}
            />
          </div>
        </Modal>
      )}
      {mainLoader && <Loader />}
    </>
  );
};

export default MyScrapShop;
